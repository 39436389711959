.club-details {
    background-color: teal;
    padding: 1rem 0;
}
.club-details p {
    color: lightgray;
    margin: 1rem auto;
}
.card {
    padding: 0.5rem;
    background-color: rgb(0, 70, 128);
    color: white;
    border-radius: 1rem;
}
.card img {
    border-radius: 1rem;
}
.card h3 {
    font-weight: 700;
}
.card h5 {
    padding: 0.3rem;
}
.sharing a {
    color: #fff;
    padding: 1rem;
    font-size: 2.5rem;
}