.btn-primary {
    background-color: #008080;
    border-color: #066;
    padding: 0.7rem 2rem;
    font-weight: 600;
}
.btn-primary:hover, .btn-primary:focus {
    background-color: #006868;
    border-color: #004949;
    padding: 0.7rem 2rem;
    font-weight: 600;
}